import React, { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { baseUrl } from '../Constant/Constant'
import { appContext } from '../App'
import { Image, Edit2} from 'react-feather';
import { useTranslation } from 'react-i18next';
import './Css/UP.css'


function Edit() {
    const [error, setError] = useState(false)
    const token = localStorage.getItem('Dirsda')
    const navigate = useNavigate()
    const {id} = useParams();
    const formref = useRef()
    let nameref = useRef()
    let descriptionref = useRef()
    const { t } = useTranslation();
 const {languages, lang} = useContext(appContext)
    
    useEffect(() => {
        fetch(baseUrl + '/DiSRDA/detail/'+id, {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(async response => {
            response = await response.json()
            console.log(response, '***')
            if(response.success){
                nameref.current.value = response.data.name
                descriptionref.current.value = parseInt(response.data.description)
                // callref.current.value = response.data.name
            } else {
                // setError(true)
            }
        })
        
    }, [])
    async function onSubmit(e){
        e.preventDefault()
        let name = e.target.name.value
        let description = e.target.description.value
        let image = e.target.image.files[0]
        let formdata = new FormData()
        formdata.append('name', name)
        formdata.append('description', description)
        formdata.append('product_image', image)



        let response = await fetch(baseUrl + '/DiSRDA/Edit/'+id, {
            body: formdata,
            method: 'put',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        response = await response.json()
        if(response.success){
            navigate('/Event')
        } else {
            setError(true)
        }
    }
    return (
        <div className="forAll">
            <form onSubmit={onSubmit} ref={formref}  className='homeCard' style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 30,
            borderRadius: 10,
            gap: 15,
            alignItems: 'center'
        }}>
            <h1 style={{color: 'white'}}>EDIT NEWS</h1>
            <input className='input' type='text' ref={nameref} placeholder='Name' name='name' required/>
            <input className='input' type='text' ref={descriptionref}  id='description' placeholder='description' name='description'  required/>
            <input type='file'  id="file"  name='image' required />
            <label className='label' for='file'><Image/></label>
            <button className='bto' type='submit'><Edit2/></button>
        </form>
        </div>
      )
    }

export default Edit
