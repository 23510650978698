import React from 'react'
import video1 from './Image/video_2024-05-30_21-23-04.mp4'
import imo from './Image/photo_2024-06-02_19-05-03.jpg'
import sport from './Image/sport.jpg'
import forest from './Image/forest.jpg'
import child from './Image/childs.jpg'
import hura from './Image/hura.jpg'
import Ox from './Image/ox.jpg'
import ko from './Image/advise.jpg'
import pump from './Image/waterpump.jpg'
import './css/Into.css'
import { useTranslation } from 'react-i18next';
import { appContext } from '../App'
import { useContext } from 'react'

function Intro() {

  const { t } = useTranslation();
  
  const {languages, lang} = useContext(appContext)

  return (
   <div className='stro'>
    {/* <ul className='fat'>
      <li className='two'>
        <h2>{languages[lang].Welcome.Ko}</h2>
        <p>{languages[lang].Welcome.Komite}</p>
      </li>
      <li className='one'><img src={ko} alt='' style={{height: 450, weight: 150}} /></li>
    </ul> */}
    <ul className='fat'>
      <li className='two'>
        <h2>{languages[lang].Welcome.CR}</h2>
        <p>{languages[lang].Welcome.car}</p>
      </li>
      <li className='one'><img src={imo} alt=''/></li>
    </ul>
    <ul className='fat'>
      <li className='two'>
        <h2>{languages[lang].Welcome.Spo}</h2>
        <p>{languages[lang].Welcome.Sport}</p>
      </li>
      <li className='one'><img src={sport} alt=''/></li>
    </ul>
    <ul className='fat'>
      <li className='two'>
        <h2>{languages[lang].Welcome.foro}</h2>
        <p>{languages[lang].Welcome.from}</p>
      </li>
      <li className='one'><img src={forest} alt=''/></li>
    </ul>
    <ul className='fat'>
    
      <li className='two'>
      <h2>{languages[lang].Welcome.Bk}</h2>
      <p>{languages[lang].Welcome.book}</p>
      </li>
      <li className='one'><img src={child} alt=''/></li>
    </ul>
    <ul className='fat'>
      <li className='two'>
        <h2>{languages[lang].Welcome.wr}</h2>
        <p>{languages[lang].Welcome.water}</p>
      </li>
      <li className='one'><img src={Ox} alt='' /><img src={pump} alt='' /></li>
    </ul>
    <ul className='fat'>
    
      <li className='two'>
        <h2>{languages[lang].Welcome.HU}</h2>
        <p>{languages[lang].Welcome.Hura}</p>
      </li>
      <li className='one'><img src={hura} alt=''/></li>
    </ul>
    <ul className='fat'>
      <li className='two'>
        <h2>{languages[lang].Welcome.Ko}</h2>
        <p>{languages[lang].Welcome.Komite}</p>
      </li>
      <li className='one'><img src={ko} alt='' style={{height: 450, weight: 150, objectFit:'contain'}} /></li>
    </ul>
   </div>
  )
}

export default Intro