import React from 'react'
import {MdPhone, MdEmail } from 'react-icons/md'
import './css/US.css'


function text() {
  return (
    <div className='conta'>
        <div className='conte'>
            <h2><MdPhone/>  +251993944303</h2>
            <h2><MdEmail/>  disrda2016@gmail.com</h2>
        </div>
    </div>
  )
}

export default text