import React from 'react'
import Hero from '../Constant/Na'
import  './Css/About.css'
import Section from '.././Constant/More'
import { ArrowDown } from 'react-feather'
import { useTranslation } from 'react-i18next';
import { appContext } from '../App'
import { useContext } from 'react'


function About() {

  const { t } = useTranslation();
  
  const {languages, lang} = useContext(appContext)

  return (
    <div>
      <Hero/>
      <div className='abo'>
      <div className='abou'>
        <h1 className='h1' style={{color: 'yellow', marginRight: 16}}>{languages[lang].About.Us}</h1>
        <p className='inro'><h3>{languages[lang].About.page}<ArrowDown style={{width: 28}}/></h3>
        </p>
        </div>
      </div>
      <Section/>
    </div>
  )
}

export default About