import React from 'react'
import video1 from './Image/video_2024-05-30_21-23-04.mp4'
import imo from './Image/istockphoto-1304020328-612x612.jpg'
import sport from './Image/waterpump.jpg'
import forest from './Image/PELUM ETHIOPIA (1).png'
import { useTranslation } from 'react-i18next';
import { appContext } from '../App'
import { useContext } from 'react'

import './css/Into.css'

function More() {

  const { t } = useTranslation();
  
  const {languages, lang} = useContext(appContext)


  return (
   <div className='stro'>
    <ul className='fat'>
      <li className='one'><img src={imo} alt=''/></li>
      <li className='two'>
        <h2>{languages[lang].About.Vision}</h2>
        <p>{languages[lang].About.VText}</p>
      </li>
    </ul>
    <ul className='fat'>
      <li className='two'>
        <h2>{languages[lang].About.Mission}</h2>
        <p>{languages[lang].About.MText}</p>
      </li>
      <li className='one'><img src={sport} alt=''/></li>
    </ul>
    <ul className='fat'>
    <li className='one'><img src={forest} alt=''/></li>
      <li className='two'>
        <h2>{languages[lang].About.Goals}</h2>
        <p>{languages[lang].About.GText}</p>
      </li>

    </ul>
    
   </div>
  )
}

export default More