import React, {useContext, useEffect, useState} from "react";
import { baseUrl } from "../Constant/Constant";
import axios from "axios";
import { appContext } from "../App";
import { Link } from "react-router-dom";
import {Edit, Trash2} from 'react-feather'
import Hero from '../Constant/Na'
import './Css/New.css'



export default function Event() {
   
    const [products, setProducts] = useState([])
    const token = localStorage.getItem('Dirsda')
    useEffect(() => {
        axios({
             method: 'get',
             url: baseUrl + '/DiSRDA/News',
             headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }).then(response => {
            if(response.data.success){
                setProducts(response.data.products)
            }
        })
        
    }, [])
  const deleteNews = async (id) => { 
   await fetch(`${baseUrl}/DiSRDA/deleteNews/${id}`, {
        method: 'get', 
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }).then(() => {
        alert("Post deleted!");
        setProducts(products.filter(pr => pr.id != id))
      });
   
  }   

    return (
   <div className="babu">
     <Hero/>
       <div className="backo">
            <div className="allh" style={{ gap: 20, display: 'flex', 'flexDirection': 'column'}}>
        <div style={{
            display: 'flex',
            gap: 20,
            alignItems: 'center',
            justifyContent: 'space-evenly',
            justifyItems: 'center',
            
        }}>
    </div>
    </div>
            <div className="allh" style={{ gap: 20}}>
            {products.length == 0 && <p>You have no products yet!</p>}
           {products.map(product => (
            <div className='pro' key={product.id}>
                <img src={baseUrl + product.image} alt='' className="iimo"/>
                <p><h1  className="cardHead" >{product.name}</h1></p>
                <p><h3 className="Content">{product.description}</h3></p>
                { token ?  (
    <>
    <div> 
                 <button><Link to={`/Edit/${product.id}`}><Edit color="orange"/></Link></button> 
                </div>
                <div> 
                    <button onClick={() => deleteNews(product.id)}><Trash2 color="red"/></button>
                </div>
    </>
) : (
    <>
       
    </>
)
}
            </div>
           ))} 
        </div>
        </div>
        </div>
      )
}



//14.00250^N, 39.72704^E