import React, {useContext, useState} from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Constant/Constant";
import Hero from '../Constant/Na'
import axios from "axios";
// import { appContext } from "../App";
// import Center from "../Center";
import { useTranslation } from 'react-i18next';
import { Image, Upload} from 'react-feather';
import './Css/UP.css'



export default function Adpost() {
    const { t } = useTranslation();
//   const {languages, lang} = useContext(appContext)
    const [error, setError] = useState(false)
    const token = localStorage.getItem('Dirsda')
    const navigate = useNavigate()
    async function onSubmit(e){
        e.preventDefault()
        let name = e.target.name.value
        let description = e.target.description.value
        let image = e.target.image.files[0]
        // let category = e.target.category.value
        // let phone = e.target.phone.value
        let formdata = new FormData()
        formdata.append('name', name)
        formdata.append('description', description)
        formdata.append('product_image', image)
        // formdata.append('category', category)
        // formdata.append('phone', phone)
        let response = await fetch(baseUrl + '/DiSRDA/upload', {
            body: formdata,
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        response = await response.json()
        if(response.success){
            navigate('/')
        } else {
            setError(true)
        }
    }
    return (
       <div>
         <Hero/>
         <div  className="forAll">
        <form onSubmit={onSubmit} className='homeCard' style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 30,
            borderRadius: 10,
            gap: 15,
            alignItems: 'center'
            
        }}>
            <h1 style={{color: 'white'}}>ADD NEWS</h1>
            {error && <p>something  is wrong please try again</p> }
            <input className='input' type='text' placeholder='Name' name='name' required/>
            <input className="input" type="text" placeholder="Put here your description" name="description" required/>
            <input type='file' id="file" name='image'  required/>
            <label className='label' for='file'><Image/></label>
            {/* <select className='inputs' name="category" id="">
                <option>Event</option>
                <option>Gallery</option></select> */}
                {/* <option value="Avocado">{t(languages[lang].sub.Avocado)}</option> */}
                {/* <option value="Barley">{(languages[lang].sub.Barley)}</option> */}
            {/* </select> */}
            <button type='submit' className='bto' ><Upload/></button>
        </form>
        </div>
       </div>
      )
    }
