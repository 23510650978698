import React, {useState, useEffect} from 'react'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import { createContext } from 'react'; 
import Navbar from './Constant/Navbar';
import Gallery from './screen/Gallery';
import Event from './screen/Event';
import Home from './screen/Home';
import Login from './Admin/Login';
import Register from './Admin/Register';
import Contact from './screen/Contact';
import News from './screen/Gallery';
import Upload from './Admin/Upload';
import Edit from './Admin/Edit';
import About from './screen/About'
import Error from './Error'
import languages from './Language';
import Body from './Body'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Body />,
    children: [
      {
        path: '/',
        element: <Home/>
      },
      {
        path: 'Login',
        element: <Login />
      }, {
        path: 'Register',
        element: <Register />
      },
      {
        path: 'Contact',
        element: <Contact />
      },
      {
        path: 'News',
        element: <News/>
      },
      {
        path: 'Upload',
        element: <Upload />
      },
      {
        path: 'About',
        element: <About />
      },{
        path: 'Event',
        element: <Event/>
      },
      {
        path: '/Edit/:id',
        element: <Edit />
      },
      {
        path: '*',
        element: <Error />
      }
    ]
  }
])
export const appContext = createContext()
 
function App() {
  const [token, setToken]= useState(null)
  const [lang, setLang] = useState('tg')


  useEffect(() => {
    let  _token = localStorage.getItem('Dirsda')
    let  _lang = localStorage.getItem('Abulica-lang')
    setLang(_lang || 'tg')
    if(_token){
      setToken(_token)

    }
  }, [])
  return (
    <appContext.Provider value={{token, setToken,  lang, setLang, languages}}>
      <RouterProvider router={router} />
    </appContext.Provider>

  );
}

export default App;
