import React, { useState, useEffect, useRef  } from 'react'
import { appContext, homeContext } from '../App'
import { useContext } from 'react'
import "./css/NAV.css";
import Logo from './Image/Disrda.jpg'
import {useNavigate, Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

 const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const {token, setToken, setLang, lang} = useContext(appContext)
  const navigate= useNavigate()
  const { t, i18n } = useTranslation();
  // const {setLang} = useContext()

  const changeLanguage = (event) => {
      setLang(event.target.value)
      localStorage.setItem('Abulica-lang', event.target.value)

      
  };



  return (
    <nav>
      <Link to="/" className="title">
       <img style={{width: 80, height: 63,}} src={Logo} />
      </Link>
      <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? "open" : ""}>
        <li>
          <Link to="/About">About</Link>
        </li>
        <li><select className='low' onChange={changeLanguage}>
              <option value={'en'} selected={lang === 'en'}>English</option>
              <option value={'tg'} selected={lang === 'tg'}>ትግርኛ</option>
              <option value={'am'} selected={lang === 'am'}>አማርኛ</option>
              <option value={'or'} selected={lang === 'or'}>AFFAN OROMO</option>
            </select></li>
        <li>
                 <Link to='/Event'>Event</Link>
        </li>
        { token ?  (
    <>
   <li> <button className='low' onClick={() => {
        localStorage.removeItem('Abulica')
        setToken(null)
        navigate('/')
    }}>LogOut</button></li>
    <li className='btn'><Link to="/Upload">Add News</Link></li>
    </>
) : (
    <>
         <li className='btn'><Link to="/Contact">Contact</Link></li>
    </>
)}
      </ul>
    </nav>
  );
};

export default Navbar