import React from 'react'
import { Outlet } from 'react-router-dom' 

export default function Body() {
  return (
    <div>
      <Outlet />
      
      {/* <Main /> */}
      {/* <h1>this is a footer</h1> */}
      <p></p>
    </div>
  )
}
