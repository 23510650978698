import React from 'react'
import { useState } from "react";
import axios from "axios";
import './Css/Send.css'
import Footer from '../Constant/Conta'
import { baseUrl } from '../Constant/Constant';
import Hero from '../Constant/Na'
import { useTranslation } from 'react-i18next';
import { appContext } from '../App'
import { useContext } from 'react'



function Contact() {

  const { t } = useTranslation();
  
  const {languages, lang} = useContext(appContext)


    const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();


  const sendMail = () => {
    axios
      .get(baseUrl + "/C", {
        params: {
          email,
          subject,
          message,
        },
      })
      .then(() => {
        //success
        console.log("success");
        alert('send')
      })
      .catch(() => {
        console.log("failure");
      });
  };

  return (
     <div>
        <Hero/>
        <div className='send'>
     <div className='back'>
     <h3 className='red'>{languages[lang].Contact.send}</h3>
     <input
        type="email"
        className='in'
        placeholder="Recipient Email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <br />
      <input
        type='text'
        className='in'
        placeholder="Name"
        onChange={(e) => setSubject(e.target.value)}
      />
      <br />
      <textarea
        className='msg'
        placeholder="Message"
        onChange={(e) => setMessage(e.target.value)}
      />
      <br />
      <button className='sub' onClick={sendMail}><h2>{languages[lang].Contact.send}</h2></button>
     </div>
    </div>
    <Footer/>
     </div>
  )
}

export default Contact