import React from 'react'
import './Css/Hello.css'
import Hero from '../Constant/Na'
import video1 from '.././Constant/Image/video_2024-05-30_21-23-04.mp4'
import Section from '.././Constant/Intro'
import { useTranslation } from 'react-i18next';
import { appContext } from '../App'
import { useContext } from 'react'



function Home() {
  const { t } = useTranslation();
  
  const {languages, lang} = useContext(appContext)
  return (
    <div>
        <Hero/>
        <div className='all'>
        <div className='Wel'>
        <h1 className='h1' style={{color: 'yellow'}}>
        {languages[lang].Welcome.wel}
        </h1>
        <p className='intro'><h3>{languages[lang].Welcome.We}</h3>
        </p>
        </div>
        {/* <div style={{width: 750, height: 500}}>
           
            <video className='vi' loop autoPlay controls >
                <source src={video1} type="video/mp4"></source>
            </video>
        </div> */}
    </div>
    <Section/>
    </div>
  )
}

export default Home