import React from 'react'
import './Css/LO.css'
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../Constant/Constant';
import { appContext } from '../App';
import { Mail, Lock} from 'react-feather';
import axios from 'axios';
import { useContext, useState, useEffect } from 'react';


function Login() {

  const [error, setError] = useState(false)
  const {setToken} = useContext(appContext)
  const navigate = useNavigate()
  async function onSubmit(event){
      event.preventDefault()
      let username = event.target.username.value
      let password = event.target.password.value
      let response = await axios({
          method: 'post',
          url: baseUrl + '/DiSRDA/Login',
          data: {
              username, password
          }
      })
      console.log(response.data)
      if(response.data.success){
          localStorage.setItem('Dirsda', response.data.token)
          setToken(response.data.token)
          navigate('/')
      } else {
          setError(true)
          
      }
  }

  return (
    <div className='full'>
        <form className='form' onSubmit={onSubmit} >
        <h3 className='red'>Login page</h3>
        <input className='input' type='text' placeholder='Enter User Name' name='username' required/> <br />
        <input className='input' type='password' placeholder='Enter Your Password' name='password' required/><br/>
        <button className='sut' ><h2>Login</h2></button>
        </form>
    </div>
  )
}

export default Login